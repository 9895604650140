<template>
  <div class="dropdown-list">
    <Dropdown
      :data="dropdown"
      v-for="dropdown in data.items"
      :key="`dropdown-${dropdown.title}`"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import Dropdown from '@/components/Dropdown.vue'

export default defineComponent({
  name: 'DropdownList',
  props: {
    data: Object,
  } as any, // eslint-disable-line
  components: {
    Dropdown,
  },
})
</script>

<style scoped lang="scss"></style>
