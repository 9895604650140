
import { defineComponent } from 'vue'

import Dropdown from '@/components/Dropdown.vue'

export default defineComponent({
  name: 'DropdownList',
  props: {
    data: Object,
  } as any, // eslint-disable-line
  components: {
    Dropdown,
  },
})
